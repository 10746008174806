import React from "react";
import { Footer as StyledFooter, FooterText, FooterLinkContainer, FooterLink, LeftContainer, RightContainer } from "./footer.styled";

const Footer = () => {
  return (
    <StyledFooter>
      <LeftContainer>
        <FooterText>© 2025 Hyperbole Inc. All rights reserved.</FooterText>
        <FooterLinkContainer>
        <FooterLink href="https://app.termly.io/document/terms-of-service/ad173f91-af61-43d7-87ae-f9a9f92a5c4c" target="_blank" rel="noopener noreferrer">Terms & Conditions</FooterLink>
        <FooterLink href="https://app.termly.io/document/privacy-policy/06133029-c29f-4bb9-b219-f8a207c304ca" target="_blank" rel="noopener noreferrer">Privacy Policy</FooterLink>
        <FooterLink href="https://forms.gle/Bsok1wsaKgo8i4Hg9" target="_blank" rel="noopener noreferrer">Feedback</FooterLink>
        </FooterLinkContainer>
      </LeftContainer>
      <RightContainer>
        <FooterText>Made with 🎩 by Showmen</FooterText>
      </RightContainer>
    </StyledFooter>
  );
};

export default Footer;