import React, { useCallback } from "react";
import PropTypes from "prop-types";
import SchedulePreferences from "./SchedulePreferences";

const ScheduleContainer = ({
  userId,
  festivalId,
  preferences,
  setPreferences,
}) => {
  // Fetch venues dynamically based on festivalId
  const fetchVenues = useCallback(async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/venues/festival/${festivalId}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch venues");
      }
      const data = await response.json();
      return data.map((venue) => ({
        id: venue.venue_id,
        name: venue.name,
      })); // Map the API response to the expected format
    } catch (error) {
      console.error("Error fetching venues:", error);
      return []; // Return an empty array in case of error
    }
  }, [festivalId]);

  return (
    <SchedulePreferences
      userId={userId}
      festivalId={festivalId}
      preferences={preferences}
      setPreferences={setPreferences}
      fetchVenues={fetchVenues} // Pass the fetchVenues function to SchedulePreferences
    />
  );
};

ScheduleContainer.propTypes = {
  userId: PropTypes.string.isRequired,
  festivalId: PropTypes.number.isRequired,
  preferences: PropTypes.object.isRequired,
  setPreferences: PropTypes.func.isRequired,
};

export default ScheduleContainer;
