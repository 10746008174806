import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Select, { components } from "react-select";
import {
  SchedulePreferencesContainer,
  TitleContainer,
  ControlsContainer,
  StyledLabel,
} from "./SchedulePreferences.styled";

// Custom Option component with checkbox
const Option = (props) => (
  <div>
    <components.Option {...props}>
      <input
        type="checkbox"
        checked={props.isSelected}
        onChange={() => null}
        style={{ marginRight: "0.1rem" }}
      />
      <label>{props.label}</label>
    </components.Option>
  </div>
);

const SchedulePreferences = ({ preferences, setPreferences, fetchVenues }) => {
  const [venues, setVenues] = useState([]);
  const [selectedVenues, setSelectedVenues] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const venueList = await fetchVenues();
        const options = venueList.map((venue) => ({
          value: venue.id,
          label: venue.name,
        }));
        setVenues(options);
        setSelectedVenues(options); // Default to all selected
      } catch (error) {
        console.error("Error fetching venues:", error);
      }
    };

    fetchData();
  }, [fetchVenues]);

  const handleBufferTimeChange = (e) => {
    setPreferences({
      ...preferences,
      bufferTime: parseInt(e.target.value, 10),
    });
  };

  const handleIncludeUnprioritizedChange = (e) => {
    setPreferences({
      ...preferences,
      includeUnprioritized: e.target.checked,
    });
  };

  const handleVenueChange = (selectedOptions) => {
    const ignoredVenues = venues
      .filter(
        (venue) =>
          !selectedOptions.some((selected) => selected.value === venue.value),
      )
      .map((venue) => venue.value);

    setSelectedVenues(selectedOptions);
    setPreferences({
      ...preferences,
      selectedVenues: selectedOptions.map((option) => option.value),
      ignoredVenues,
    });
  };

  return (
    <SchedulePreferencesContainer>
      <TitleContainer>
        <div>Schedule Generation Preferences</div>
      </TitleContainer>
      <ControlsContainer>
        <StyledLabel htmlFor="bufferTime">Buffer Time:</StyledLabel>
        <select
          id="bufferTime"
          value={preferences.bufferTime}
          onChange={handleBufferTimeChange}
        >
          <option value="30">30 minutes</option>
          <option value="45">45 minutes</option>
          <option value="60">60 minutes</option>
          <option value="75">75 minutes</option>
          <option value="90">90 minutes</option>
        </select>
        <StyledLabel>
          <input
            type="checkbox"
            checked={preferences.includeUnprioritized}
            onChange={handleIncludeUnprioritizedChange}
          />
          Fill Gaps
        </StyledLabel>
        <StyledLabel htmlFor="venueSelect">Venues:</StyledLabel>
        <Select
          id="venueSelect"
          isMulti
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          options={venues}
          value={selectedVenues}
          onChange={handleVenueChange}
          placeholder="Select venues..."
          menuPlacement="bottom" // Always expand downward
          menuPortalTarget={document.body} // Render dropdown outside parent container
          menuShouldScrollIntoView={true} // Ensure dropdown scrolls into view if needed
          components={{
            Option, // Use the custom Option component with checkboxes
            ValueContainer: ({ children, ...props }) => {
              const selectedCount = props.getValue().length;
              const label =
                selectedCount === venues.length
                  ? "All Venues Selected"
                  : `${selectedCount} Venue${
                      selectedCount !== 1 ? "s" : ""
                    } Selected`;
              return (
                <components.ValueContainer {...props}>
                  {label}
                </components.ValueContainer>
              );
            },
          }}
          styles={{
            control: (base) => ({
              ...base,
              width: "250px", // Set fixed width
              color: "black", // Ensure text is black
            }),
            menu: (base) => ({
              ...base,
              zIndex: 9999, // Ensure dropdown appears on top
            }),
            menuList: (base) => ({
              ...base,
              maxHeight: "200px", // Set a max height for the dropdown
              overflowY: "auto", // Allow scrolling if items exceed max height
              color: "black",
            }),
            multiValue: () => ({
              display: "none", // Hide individual selected values
            }),
            option: (base, state) => ({
              ...base,
              backgroundColor: state.isSelected ? "#d3d3d3" : "white",
              color: "black",
              ":hover": {
                backgroundColor: "#e6e6e6",
              },
            }),
          }}
        />
      </ControlsContainer>
    </SchedulePreferencesContainer>
  );
};

SchedulePreferences.propTypes = {
  preferences: PropTypes.shape({
    bufferTime: PropTypes.number.isRequired,
    includeUnprioritized: PropTypes.bool.isRequired,
    applyPopularityBuffer: PropTypes.bool.isRequired,
    selectedVenues: PropTypes.array,
  }).isRequired,
  setPreferences: PropTypes.func.isRequired,
  fetchVenues: PropTypes.func.isRequired,
};

export default SchedulePreferences;
